import {
    Button,
    Tooltip,
    Typography,
    Badge,
    Grid
} from '@material-ui/core';

import {
    Event as EventIcon,
    Home as HomeIcon,
    Star as StarIcon,
    Euro as EuroIcon,
    KingBed as KingBedIcon,
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    FilterList as FilterListIcon,
    SupervisorAccount as SupervisorAccountIcon,
    StickyNote2 as StickyNote2Icon,
    Archive as ArchiveIcon,
    DriveFileRenameOutline as DriveFileRenameOutlineIcon,
    Warning as WarningIcon,
    Error as ErrorIcon,
    Chat as ChatIcon,
    Description as DescriptionIcon,
    MarkUnreadChatAlt as MarkUnreadChatAltIcon
} from '@mui/icons-material';

import {
    getDictsLabelId,
    buildFilterSelect,
    buildFilterSlider,
    buildFilterSwitch
} from '../../../Components/Chavelusa/FormGroup/utils';

import { is_agent, canReadGenericData } from '../../../../Services/permissions';

import { reduceString } from '../../../utils';

import { propertyForm } from '../../../Components/Realstate/Property/Form/launcher';


/**
 * This variable defines the cols of the clients table
 */
const cols_prepare = [
    {
        id: 'banner',
        label: 'Fotografia',
        width: '10%'
    },
    {
        id: 'id',
        label: 'Número',
        sort: true,
    },
    {
        id: 'user__user__first_name',
        label: 'Nome',
        sort: true
    },
    {
        id: 'user__user__date_joined',
        label: 'Adicionado',
        sort: true
    },
    {
        id: 'contact',
        label: 'Contacto'
    },
    {
        id: 'email',
        label: 'Email'
    },
    {
        id: 'operations',
        label: 'Operações'
    },
];

export const cols = (lowDenseMode) => cols_prepare;

/**
 * This method returns an array of react components, one for each column of the table
 * @param {Object} obj A client object fom the list retrieved from the /client_agents API endpoint
 * @returns {Component[]} One component per column of the table for the client row
 */
export const getRow = (obj, edit, editPhoto) => {
    let row = [];
    // Photo
    row.push(
        <Grid
            p={10}
            m={-2}
            sx={{ position: 'relative' }}
        >
            <Grid
                sx={{
                    backgroundImage: 'url(' + process.env.REACT_APP_STATIC + obj.image + ')',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    width: '100%', height: '100%',
                    position: 'absolute', right: 0, left: 0, top: 0, bottom: 0
                }}
            ></Grid>
        </Grid>
    )
    // Number 
    row.push(<>{obj.id}</>);
    // Name
    row.push(<>{obj.name}</>);
    // Created
    row.push(
        <>
            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {new Date(obj.created).toLocaleDateString('pt-PT')}
        </>
    );
    // Contact
    row.push(<>{obj.contact}</>);
    // Email
    row.push(<>{obj.email}</>);
    // Operations
    row.push(
        <Grid container direction="row">
            <Button
                onClick={edit}
                sx={{ mr: 1, mb: 1 }}
                variant="outlined"
            >
                Editar dados
            </Button>
            <Button
                onClick={editPhoto}
                sx={{ mr: 1, mb: 1 }}
                variant="outlined"
            >
                Editar fotografia
            </Button>


        </Grid>
    );
    return row;
}

/**
 * Builds the clients table filters, given data retrieved from the client_agents/filters API endpoint
 * @param {Object} data The object returned from the API
 * @returns {Object[]} filters An aray of objects that define the filters fields
 */
export const buildFilters = (data) => {
    let filters = [];

    return undefined;
}

/**
 * This methos builds the tutorial object
 */
const buildTutorial_partial = []
export const buildTutorial = buildTutorial_partial;

/**
 * Builds the export objects for SelectTable
 * @param {String} baseUrl 
 */
export const generate_exports = (baseUrl) => {
    return []
}

// ADD NEW
export const addNew = (goBack, updateEntity, onChange) => {
    return propertyForm(undefined, goBack, updateEntity, onChange);
}

// FLAGS 
export const enableSelect = false;
export const enableSearch = true;