import React, { useState, useEffect } from "react";

import FormGroup from "../FormGroup";

import {
    Grid,
    Typography,
    CircularProgress
} from '@mui/material';

import {
    requestGET,
    requestWithBody,
    handleRequestError
} from "../../../utils";

/**
 * 
 * 
 * NOTE: Accepts fields ids with dots to define inner object properties
 * For example, 'type.id' is a valid form name for a field defined as type: {id: } in initialVals, the same structure used for submit
 * 
 * FIELDS EXTENSION WITH API DATA 
 *  api: {
 *      ---- REQUIRED ONES
        url: '/crm/client_agents?exclude_migrated=true&search=<searchTerm>&agent=<dependantValue>',
        labelTag: 'client.name',
        idTag: 'client.id',
        ---- OPTIONALS 
        dependsOn: 'agent_id', (includes dependant value on get, replacing <dependantValue> on url)
        async: true, (when set, loads async when value set and changes, replacing <searchTerm> on url)
        urlBase: process.env.REACT_APP_API_URL (default)
        bodyAttr: 'states' (when response is not array of objects, but object with array, set key of array)
        
        multiple: true (when set, response is array of objects, each with several options. Ceates one field for each group, duplicating the one defined)
        groupTag: 'name', (for each group, its label)
        groupArray: 'features', (for each group, its options)

        patchArray: 'feature', (when set, allows to set multiple values, being the array key defined here)
        patchObject: 'id' (values are set inside an object with this key)
    },
 */
let loadingMonitor = [];
let abortMonitor = [];

const FormGroupLoader = ({
    // FormGroup parameters
    fields, sx = {}, updateParams, initialVals = {}, setInvalid, onDelete,
    // Extra parameters
    title, subtitle, urlPost, edit, onSubmitSuccess
}) => {

    // Internal state
    const [loading, setLoading] = useState(true);
    const [loadingInit, setLoadingInit] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(undefined);
    const [localFields, setLocalFields] = useState([]);
    const [localInitVals, setLocalInitVals] = useState(undefined);
    const [localVals, setLocalVals] = useState({});
    const [apiDependenciesTriggers, setApiDependenciesTriggers] = useState({}); // Key: id of field that triggers dependency (value)

    // Helpers

    useEffect(() => {
        console.log("LOADING MONITOR", [...loadingMonitor], loading);
    }, [loading]) 
    
    const getDataFromApi = (field, index, dependantValue, searchTerm) => {
        // cancel pending request if any
        if(abortMonitor[index]) abortMonitor[index].abort();
        // make our request cancellable
        abortMonitor[index] = new AbortController();
        const signal = abortMonitor[index].signal;

        var apiData = field.api;
        // Get data for field values from API
        var url = (apiData.urlBase ? apiData.urlBase : process.env.REACT_APP_API_URL) + apiData.url;
        if (!!apiData.dependsOn && !dependantValue || !!apiData.async && !searchTerm)
            return; // Abort request when conditions are not met
        if (!!apiData.dependsOn) // Dependencies
            url = url.replaceAll("<dependantValue>", !!dependantValue ? dependantValue : '');
        if (!!apiData.async) // Real time filtering
            url = url.replaceAll("<searchTerm>", !!searchTerm ? encodeURIComponent(searchTerm) : '');

        loadingMonitor[index] = true;
        setLoading(loadingMonitor.length && loadingMonitor.some((s) => !!s));

        requestGET(url, signal)
            .then(response => {
                loadingMonitor[index] = false;
                var resultArray = apiData.bodyAttr ? response[apiData.bodyAttr] : response;
                setLocalFields(old => {
                    // Clone array, so that FormGroup can listen to props changes
                    let f_copy = [...old];
                    // If multiple, append because it was ignored when creating local fields
                    if (apiData.multiple) {
                        response.forEach(r => {
                            f_copy.push({
                                ...field,
                                label: r[apiData.groupTag],
                                values: r[apiData.groupArray].map(v => {
                                    return { label: getDepthValue(v, apiData.labelTag), id: getDepthValue(v, apiData.idTag) }
                                })
                            })
                        });
                    } else {
                        f_copy[index] = {...f_copy[index], 
                            values: resultArray.map(r => {
                                return { label: getDepthValue(r, apiData.labelTag), id: getDepthValue(r, apiData.idTag) }
                            })
                        };
                        console.log("FIELD API UPDATE", searchTerm, f_copy[index].values);
                    }
                    return f_copy;
                });
                setLoading(loadingMonitor.length && loadingMonitor.some((s) => !!s));
                setLoadingInit(loadingMonitor.length && loadingMonitor.some((s) => !!s))
            })
            .catch(e => {
                console.error(e);
            })
    }
    const getDepthValue = (instance, key) => {
        const split = key.split('.');
        if (split.length>1 && instance[split[0]]) {
            // If array, return array of object values with key
            if (Array.isArray(instance[split[0]])) {
                return instance[split[0]].map(
                    i => 
                    getDepthValue(i, split.slice(1).join('.'))
                );
            }
            // Otherwise, return object value
            return getDepthValue(instance[split[0]], split.slice(1).join('.'));
        }
        return instance[split[0]];
    }
    const setDepthValue = (instance, key, val) => {
        const split = key.split('.');
        if (split.length>1)
            instance[split[0]] = setDepthValue(instance[split[0]] ? instance[split[0]] : {}, split.slice(1).join('.'), val);
        else 
            instance[split[0]] = val;
        return instance;
        
    }

    // Initialize
    useEffect(() => {
        abortMonitor.forEach(am => {if(am) {am.abort()}});
        loadingMonitor = [];
        abortMonitor = [];

        // Initialize local fields
        setLocalFields(fields.filter(f => !f.api || !f.api.multiple).map(f => {return {...f}}));

        // Initial vals should only be those defined as field (ignore others)
        const fieldsIds = fields.map(f => f.id);
        const locals = {};
        if (initialVals) { // Define initial accepting dots in fields names (for example type.id)
            fieldsIds.forEach(fid => {
                const val = getDepthValue(initialVals, fid);
                if (val!==null && val!==undefined || val===false)
                    locals[fid] = val;
            });
        }
        setLocalInitVals(Object.keys(locals).length>0 ? locals : undefined);
        setLocalVals(Object.keys(locals).length>0 ? locals : {});

        // For each field, check those that need to get data from API
        // api: {url: <String>, labelTag: <String>, idTag: <String>, async: <bool>}
        // When async, true, expects paginated results, that are searchable
        fields.forEach((f, index) => {
            abortMonitor.push(null);
            if (!!f.api && !f.api.dependsOn && !f.api.async) {
                loadingMonitor.push(true);
                getDataFromApi(f, index);
            } else {
                loadingMonitor.push(false);
                if (!!f.api && f.api.dependsOn)
                    setApiDependenciesTriggers(old => {
                        return {...old, [f.api.dependsOn]: f.id};
                    });
            }
        });
        console.log("apiDependenciesTriggers", apiDependenciesTriggers);
        setLoading(loadingMonitor.length && loadingMonitor.some((s) => !!s));
        setLoadingInit(loadingMonitor.length && loadingMonitor.some((s) => !!s));
    }, []);

    // Form handlers
    const onSubmit = (vals) => {
        setSubmitting(true);

        console.log("Submit " + title + "...", vals);
        // Convert empty fields in null ones
        Object.keys(vals).forEach(k => {
            if (vals[k]==="") {
                var field = localFields && localFields.find(f => f.id === k);
                if (field && ['multipleselect', 'multipleselectchips'].includes(field.type))
                    vals[k] = [];
                else if (field && ['switch'].includes(field.type))
                    vals[k] = false;
                else 
                    vals[k] = null;
            }
        });

        // Convert flat field names in structured ones (Ex. type.id will be converted in type: {id : })
        let finalVals = {};
        Object.keys(vals).forEach(k => {
            var field = localFields && localFields.find(f => f.id === k);
            console.log("submittin g field", k, field, vals[k]);
            if (!field || field.disabled) return; // Ignore disabled fields
            setDepthValue(
                finalVals, 
                !!field.api && !!field.api.patchArray ? field.api.patchArray : k, 
                !!field.api && !!field.api.patchObject && Array.isArray(vals[k]) 
                    ? vals[k].map(v => setDepthValue({}, field.api.patchObject, v)) 
                    : ['switch'].includes(field.type) && !vals[k]  // Preventing null values for switch fields
                    ? false
                    : vals[k]
            );
        }); 

        // POST/PATCH to API
        requestWithBody(
            edit ? "PATCH" : "POST",
            process.env.REACT_APP_API_URL + urlPost,
            finalVals
        ).then(onSubmitSuccess).catch(error =>
            handleRequestError(
                error,
                fields.map(f => f.id),
                edit ? "Error updating with form group loader!" : "Error creating with form group loader!"
            )
                .then(e => setError(e))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    const onChange = (to_clean, id, val) => {
        console.log("onChange", to_clean, id, val, apiDependenciesTriggers);
        var fieldIndex = localFields.findIndex(f => f.id === id);
        console.log("field changed", fieldIndex);
        if (fieldIndex<0) return;
        console.log("field changed", localFields[fieldIndex]);
        // Check if there are dependencies triggered
        if (Object.keys(apiDependenciesTriggers).indexOf(id)>=0) {
            var dependencyIndex = localFields.findIndex(f => f.id === apiDependenciesTriggers[id]);
            console.log("dependencyIndex", localFields, id, apiDependenciesTriggers[id], dependencyIndex);
            if (!dependencyIndex) return;
            // If triggers dependency and has value, load dependency data from API
            if (val) {
                getDataFromApi(localFields[dependencyIndex], dependencyIndex, val);
            } else {
                console.log("FIELD API UPDATE CLEAR", val);
                setLocalFields(old => {
                    // Clone array, so that FormGroup can listen to props changes
                    let f_copy = [...old];
                    f_copy[dependencyIndex]['values'] = [];
                    return f_copy;
                });
            }
        }
        // Update local values
        setLocalVals(oldV => {return {...oldV, [id]: val}});
        // Propagate call to upper components
        updateParams && updateParams(to_clean, id, val);
    }

    const onSearch = (id, searchTerm) => {
        var fieldIndex = localFields.findIndex(f => f.id === id);
        console.log("field search", fieldIndex);
        if (fieldIndex<0) return;
        console.log("field search", localFields[fieldIndex]);
        if (localFields[fieldIndex].api && localFields[fieldIndex].api.async) { // If field allows async search, trigger API search (if also has dependant value, also pass it)
            console.log("ASYNC", localFields[fieldIndex], searchTerm, localFields[fieldIndex].api.dependsOn, localVals);
            getDataFromApi(localFields[fieldIndex], fieldIndex, !!localFields[fieldIndex].api.dependsOn ? localVals[localFields[fieldIndex].api.dependsOn] : undefined, searchTerm);
        }
    }

    return (
        <Grid container direction="column">
            <Typography variant="h6" mb={0}>
                {title}
                {
                    loading && <CircularProgress size={16} sx={{ ml: 1 }} />
                }
            </Typography>
            {
                subtitle && 
                <Typography variant="caption" sx={{mr: 4}}>{subtitle}</Typography>
            }
            {
                !loadingInit &&
                <FormGroup
                    fields={localFields}
                    onSubmit={onSubmit}
                    disabled={submitting}
                    errors={error}
                    setErrors={setError}
                    initialVals={localInitVals}
                    sx={{
                        ...sx,
                        minWidth: '100%',
                        maxWidth: '100%',
                        mr: 0,
                        my: 1
                    }}

                    updateParams={onChange}
                    setInvalid={setInvalid}
                    onDelete={onDelete}
                    onSearch={onSearch}
                />
            }
        </Grid>
    );
}

export default FormGroupLoader;