import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button
} from '@mui/material';

import FilesUploader from "../../../Chavelusa/FilesUploader";

const agentFormPicture = (entity, updateEntity, onChange) => {

    return (
        <Grid sx={{ width: '100%' }}>
            <Typography variant="h6" mb={3}>
                Editar fotografia
            </Typography>

            <FilesUploader
                id={'imageUpdate'}
                url={process.env.REACT_APP_API_URL + '/realstate/agents/' + entity.id + '/'}
                filesAttr={'image'}

                initialFiles={[]}
                maxFiles={1}
                update={updateEntity}
                allowDelete={false}

                method={"PATCH"}
                initialBody={{}}
            />

        </Grid>
    );
}

export default agentFormPicture;