import {
    AppRegistration,
    Home as HomeIcon,
    MenuOpen,
    Newspaper,
    Person as PersonIcon,
    SupervisorAccount,
    Tv as TvIcon,
    ViewComfy
} from '@mui/icons-material';

import { Badge, Menu } from '@mui/material';
import { canWriteAdministrativeData } from '../../../Services/permissions';

export const getMenu = () => {
    let menu = []; // List of {label: <str>, menu: <array>}

    var oldPortal = {menu: []};
    oldPortal.menu.push({
        icon: <HomeIcon />,
        label: 'Início',
        url: '/',
    });
    menu.push(oldPortal);

    var realstate = {label: 'Propriedades', menu: []};
    realstate.menu.push({
        icon: <HomeIcon />,
        label: 'Propriedades',
        url: '/propriedades'
    });
    realstate.menu.push({
        icon: <TvIcon />,
        label: 'Apresentações',
        url: '/apresentacoes'
    });
    menu.push(realstate);
    
    var crm = {label: 'Clientes', menu: []};
    crm.menu.push({
        icon: <PersonIcon />,
        label: 'Clientes',
        url: '/clientes'
    });
    menu.push(crm);

    if (canWriteAdministrativeData) {
        var external = {label: 'Serviços externos', menu: []};
        external.menu.push({
            icon: <Newspaper />,
            label: 'Anúncios',
            url: '/anuncios'
        });
        external.menu.push({
            icon: <AppRegistration />,
            label: 'Aplicações',
            url: process.env.REACT_APP_ADMIN_URL + '/apps'
        });
        menu.push(external);

        var admin = {label: 'Administração', menu: []};
        admin.menu.push({
            icon: <Badge badgeContent={'Novo'} color={'primary'}><SupervisorAccount /></Badge>,
            label: 'Agentes',
            url: '/agentes'
        });
        menu.push(admin);
    
        var publicP = {label: 'Portal público', menu: []};
        publicP.menu.push({
            icon: <MenuOpen />,
            label: 'Menu',
            url: process.env.REACT_APP_ADMIN_URL + '/portal/menu'
        });
        publicP.menu.push({
            icon: <ViewComfy />,
            label: 'Páginas',
            url: process.env.REACT_APP_ADMIN_URL + '/portal/paginas'
        });
        menu.push(publicP);
    }
    console.log("menu", menu);



    return menu;
}