
import FormGroupLoader from "../../../Chavelusa/FormGroupLoader";

import { buildFilterText, buildFilterSelect, validatorEmail, validatorMin3Chars, validatorPhone, validatorIsInteger } from "../../../Chavelusa/FormGroup/utils";

export const agentForm = (entity, updateEntity, onChange) => {
    return (<FormGroupLoader
        title={entity ? 'Editar agente' : 'Criar agente'}
        subtitle={entity ? '' : 'A criação é um processo assíncrono que obriga à intervenção manual dos serviços de informática para a criação da caixa de correio eletrónico e comunicação das credenciais ao agente. Este procedimento é despoletado após a submissão do formulário e poderá levar até 24 horas a ser executado. Irá receber um email quando este for concluído.'}
        urlPost={'/realstate/agents/' + (!!entity ? entity.id + '/' : '')}
        edit={!!entity}
        initialVals={{...entity}}
        onSubmitSuccess={updateEntity}
        updateParams={onChange}
        fields={[

            {...buildFilterText('Número de agente', 'id', true, validatorIsInteger),
                info: !!entity && entity.created ? 'O número do agente não pode ser editado' : undefined,
                disabled: !!entity && entity.created
            },

            buildFilterText('Nome', 'name', true, validatorMin3Chars, 'O nome deve ter no mínimo 3 caracteres.'),
            
            {...buildFilterText('Email', 'email', true, !!entity && entity.created ? undefined : validatorEmail), 
                info: !!entity && entity.created ? 'O email não pode ser editado' : undefined,
                disabled: !!entity && entity.created
            },

            buildFilterText('Contacto', 'contact', true, validatorPhone),

        ]}
    />);
}