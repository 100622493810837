import React, { useState, useEffect, createElement } from "react";

import {
    is_agent,
    canWriteSensitiveData
} from '../../../../Services/permissions';

import {
    openChaveLusaAdmin,
    requestGET,
    handleRequestError,
    requestWithBody
} from '../../../utils';

import {
    Grid,
    Box,
    Tabs,
    Tab,
    CircularProgress,
    Typography,
    Button,
    Link,
    Chip,
    Alert
} from '@mui/material';

import ChaveLusaAlert from '../../../Components/Chavelusa/Alert';

import PropertyPublishedBanner from "../../../Components/ExternalApps/PropertyPublished/Banner";
import PropertyDocumentBanner from "../../../Components/Realstate/PropertyDocument/Banner";
import PropertyDocumentForm from "../../../Components/Realstate/PropertyDocument/Form";
import PropertyImageBanner from "../../../Components/Realstate/PropertyImages/Banner";
import FilesUploaderManagement from "../../../Components/Chavelusa/FilesUploaderManagement";
import PropertyStatsBanner from "../../../Components/Realstate/PropertyStats/Banner";
import ClientAgentBanner from "../../../Components/CRM/Client/Banner";
import FormGroup from "../../../Components/Chavelusa/FormGroup";
import PropertyAdvert from "../../../Components/Realstate/Property/Advert";
import PropertyPrivateData from "../../../Components/Realstate/Property/PrivateData";
import ActivityLogTable from "../../../Components/Backoffice/ActivityLog/Table";
import PropertySheetTemplateCreator from "../../../Components/Realstate/Property/Sheets/Creator";

import { buildFilterDateTime } from "../../../Components/Chavelusa/FormGroup/utils";

import { buildDateFiltersSuggestions } from "../../../../Services/feed";
import { buildTagsForClientAgentForProperty } from "../../../Components/CRM/Client/Banner/utils";

import { compareClientAgentForProperty } from "./utils";

import { canReadAdministrativeData } from "../../../../Services/permissions";

import { 
    PROPERTY_STATUS_ARCHIVED, 
    PROPERTY_STATUS_PUBLISHED,
    PROPERTY_STATUS_ALLOW_WRITE_ALWAYS,
 } from "../../../../Models/property";
import { useLocation, useNavigate } from "react-router";

export const FEED = "Feed";
export const PUBLIC = "Anúncio";
export const PRIVATE = "Ficha do imóvel";
export const IMAGES = "Imagens";
export const DOCUMENTS = "Documentos";
export const APPS = "Serviços externos";
export const CLIENTS = "Clientes";
export const STATS = "Estatísticas";
export const LOG = "Histórico";


const TABS = canReadAdministrativeData ? {
    [PUBLIC]: [PUBLIC],
    [PRIVATE]: [PRIVATE],
    [IMAGES]: [IMAGES],
    [DOCUMENTS]: [DOCUMENTS],
    [APPS]: [APPS],
    [CLIENTS]: [CLIENTS],
    [STATS]: [STATS],
    [LOG]: [LOG]
} : {
    [PUBLIC]: [PUBLIC],
    [PRIVATE]: [PRIVATE],
    [IMAGES]: [IMAGES],
    [DOCUMENTS]: [DOCUMENTS],
    [APPS]: [APPS],
    [CLIENTS]: [CLIENTS],
    [STATS]: [STATS]
}

const jsWindow = window;

const Feed = ({ instance, window, windowChanged, windowChangedState, setWindow, windowPipePop, setNotify, onUpdate }) => {

    // On load, initialize variables
    useEffect(() => {
    }, []);

    // Window monitoring (to know which feed element is selected)
    const [windowId, setWindowId] = useState(null);
    useEffect(() => {
        // When window is null, windowId should be null as well
        if (window === null) {
            console.log("window changed null");
            setWindowId(null);
        }
    }, [window]);

    const openWindow = (w, id = null, transition = false) => {
        if (transition) {
            setWindow(null);
            setTimeout(() => {
                setWindow(w);
                setWindowId(id);
            }, 500);
        } else {
            setWindow(w);
            setWindowId(id);
        }
    }

    // Filters
    const [filters, setFilters] = useState({}); // {TAB: [{label: <>, value: <>}], ...}
    const [filter, setFilter] = useState(null); // <options.value>

    const [filtersTime, setFiltersTime] = useState({}); // {TAB: {start: <Timestamp>, end:<Timestamp>}}
    const [filtersTimeSuggestion, setFiltersTimeSuggestion] = useState({}); // {TAB: [{label: <String>, action: <Method>, selected: <Method>}, ...]}
    const [filterTime, setFilterTime] = useState(null); // {from: <Datetime>, to: <Datetime>}

    // Tabs set up
    const urlSearch = useLocation().search;

    const [tab, setTab] = useState(PUBLIC);

    useEffect(() => {
        if (new URLSearchParams(urlSearch).get("tab") === 'externalApps') setTab(APPS);
    }, [])

    useEffect(() => {
        console.log("NEW TAB", tab);
        // When tab changes, close window
        setWindow(null);
        // Reset filters
        setFilter(null);
        setFilterTime(null);
    }, [tab]);

    // On load, populate user feed
    const loadingMonitor = [true, true, true, true, true, canReadAdministrativeData];
    const [loading, setLoading] = useState(true);
    const [feed, setFeed] = useState([]);
    useEffect(() => {
        if (!instance) return false;
        setFeed([]);

        // Get external apps (and then proeprty published for them)
        // Structure: {feed: APPS, id: <app.id>, name: <app.name>, updated: <Date if pp>, pp: <PropertyPublishedSerializer>}
        requestGET(process.env.REACT_APP_API_URL + '/externalApps/apps')
            .then(response => {
                response = response.map(r => { r.pp = null; return r; });
                requestGET(process.env.REACT_APP_API_URL + '/externalApps/pps?property=' + instance.id)
                    .then(responsePP => {
                        responsePP.forEach(rpp => {
                            var app = response.find(r => r.id === rpp.app.id);
                            if (app) {
                                app.pp = rpp;
                                app.last_changed = rpp.last_sync;
                            }
                        });

                        let apps = response.map(r => { return { ...r, feed: APPS } });
                        console.log("GOT APPS", apps);
                        setFeed(olde => [...olde, ...apps]);
                        loadingMonitor[0] = false;
                        if (!loadingMonitor.some(l => l)) setLoading(false);
                    }).catch(error =>
                        handleRequestError(error, [], "Error getting property publisheds")
                    );

            }).catch(error =>
                handleRequestError(error, [], "Error getting apps")
            );

        // Get documents
        requestGET(process.env.REACT_APP_API_URL + '/realstate/documents?property=' + instance.id)
            .then(response => {
                // Update response to force urls https
                if (response && process.env.REACT_APP_API_URL.includes("https")) {
                    response.forEach(r => r.document = r.document.replace("http", "https"))
                }


                let docs = response.map(r => { return { ...r, feed: DOCUMENTS } });
                console.log("GOT DOCUMENTS", docs);

                // Update feed 
                setFeed(olde => [...olde, ...docs]);

                // Loading monitor update
                loadingMonitor[1] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(error, [], "Error getting documents")
            );

        // Get images
        requestGET(process.env.REACT_APP_API_URL + '/realstate/images?property=' + instance.id)
            .then(response => {
                let images = response.map(r => { return { ...r, feed: IMAGES } });
                console.log("GOT IMAGES", images);

                // Update feed 
                setFeed(olde => [...olde, ...images]);

                // Loading monitor update
                loadingMonitor[2] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(error, [], "Error getting images")
            );

        // Get stats filters
        requestGET(process.env.REACT_APP_API_URL + '/realstate/stats/timespan?property=' + instance.id)
            .then(response => {
                // Update feed (add placeholder, as stats vary according to the filter)
                setFeed(olde => [...olde, { feed: STATS, start: response.start, end: response.end }]);

                setFiltersTime(oldft => {
                    oldft[STATS] = { start: response.start, end: response.end }
                    setFiltersTimeSuggestion(oldFTS => {
                        oldFTS[STATS] = buildDateFiltersSuggestions(oldft[STATS]);
                        return oldFTS;
                    });
                    return oldft;
                })

                // Loading monitor update
                loadingMonitor[3] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(error, [], "Error getting stats timespans")
            );

        // Get clients
        requestGET(process.env.REACT_APP_API_URL + '/crm/client_agents/forProperty/' + instance.id)
            .then(response => {
                let clients = response.map(r => { return { ...r, feed: CLIENTS } });
                console.log("GOT CLIENTS", clients);

                // Update feed 
                setFeed(olde => [...olde, ...clients]);

                // Loading monitor update
                loadingMonitor[4] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(error, [], "Error getting clients")
            );

        getActivityLog();
    }, []);

    const getActivityLog = () => {
        // Get activity log
        requestGET(process.env.REACT_APP_API_URL + '/backoffice/activityLog?model=re.pr&model_id=' + instance.id)
            .then(response => {
                console.log("GOT LOG", response);

                // Update feed 
                setFeed(olde => [...olde.filter((f) => f.feed !== LOG), {
                    feed: LOG,
                    log: response
                }]);

                // Loading monitor update
                loadingMonitor[5] = false;
                if (!loadingMonitor.some(l => l)) setLoading(false);
            }).catch(error =>
                handleRequestError(error, [], "Error getting activity log")
            );
    }

    // When feed changes, update filters 
    useEffect(() => {
        console.log("FEED CHANGED");

        if (!feed) return;

        // Get set of categories for each feed type (non-repeated)
        let docs = feed.filter(r => r.feed === DOCUMENTS && r.category != null).map(r => r.category);
        docs = [...new Map(docs.map(item => [item['id'], item])).values()].sort((a, b) => a.name > b.name ? 1 : -1);
        let images = feed.filter(r => r.feed === IMAGES && r.label != null).map(r => r.label);
        images = [...new Map(images.map(item => [item['id'], item])).values()].sort((a, b) => a.label > b.label ? 1 : -1);
        let clients = [];
        if (feed.filter(r => r.feed === CLIENTS).length) {
            clients = [
                { label: 'Todos', value: undefined },
                { label: 'Contactos na propriedade', value: 'forms' },
                { label: 'Contactos PENDENTES na propriedade', value: 'forms_pending' },
                { label: 'Classificados na propriedade', value: 'classifications' },
                { label: 'Visitas na propriedade', value: 'visits' },
                { label: 'Visitas FUTURAS na propriedade', value: 'visits_next' }
            ];
        }

        setFilters(oldf => {
            oldf[DOCUMENTS] = [{ label: 'Todos', value: undefined }, ...docs.map(c => { return { label: c.name, value: c.id } })];
            oldf[IMAGES] = [{ label: 'Todos', value: undefined }, ...images.map(c => { return { label: c.label, value: c.id } })];
            oldf[CLIENTS] = clients
            return oldf;
        });
    }, [feed]);

    // When an instance is updated, update it in feed
    const updateFeed = (instance, label, changeWindowToElement = false, remove = false, reloadInstance = false) => {
        console.log("updateFeed", label, changeWindowToElement, instance);
        // If changeWindowToElement set to true, change element selected
        changeWindowToElement && setWindowId(instance.id + '/' + label);

        // Update feed
        setFeed(oldfeed => {
            const olde = [...oldfeed];
            const indexToReplace = olde.findIndex(i => i.feed === label && i.id === instance.id);

            // If no index to replace, then it is new, just push to 
            if (indexToReplace === -1) {
                olde.push({ ...instance, feed: label });
                // Otherwise, update element at indexToReplace
            } else {
                if (remove)
                    olde.splice(indexToReplace, 1);
                else
                    olde.splice(indexToReplace, 1, { ...instance, feed: label });
            }
            return olde;
        });

        // Reload instance from API 
        if (reloadInstance)
            onUpdateLocal();
    }

    // Windows 
    const openDocumentFormWindow = (doc, addAnother) => {
        openWindow(<PropertyDocumentForm
            doc={doc}
            property={instance}
            updateElement={(instance) => {
                // Propagate changes to feed
                updateFeed(instance, DOCUMENTS);
            }}
            close={() => openWindow(null)}
            notify={setNotify}
        />, null, true);
    }

    const openImageFormWindow = (edit, sort) => {
        openWindow(<FilesUploaderManagement
            instances={edit ? feed.filter(f => f.feed === IMAGES) : []}
            
            title={edit ? 'Editar imagens' : 'Carregar imagens'}
            upload={!edit}
            uploadId={'image'}
            uploadUrl={process.env.REACT_APP_API_URL + '/realstate/images/'}
            uploadInitialBody={{ 'property': instance.id }}
            editUrlAppend={'/?property=' + instance.id}
            reorderUrl={sort ? process.env.REACT_APP_API_URL + '/realstate/images/order/?property=' + instance.id : null}
            categoriesUrl={!sort ? (process.env.REACT_APP_API_URL + '/realstate/images/labels/') : undefined}

            updateElement={(instance, remove, reloadInstance) => {
                // Propagate changes to feed
                // updateFeed = (instance, label, changeWindowToElement = false, remove = false, reloadInstance = false) => {
                updateFeed(instance, IMAGES, false, remove, reloadInstance);
            }}
            close={() => openWindow(null)}
            notify={setNotify}
            banner={PropertyImageBanner}
            extraBannerProps={{property: instance}}
        />, null, true);
    }

    const openPropertySheetFormWindow = () => {
        openWindow(<PropertySheetTemplateCreator
            property={instance}
            propertyImages={feed && feed.filter(f => f.feed === IMAGES)}
            close={() => openWindow(null)}
        />, null, true);
    }

    // Alerts
    const [alert, setAlert] = useState(undefined);

    const deleteAllImages = () => {
        setWindow(null);
        setLoading(true);

        requestWithBody("DELETE", process.env.REACT_APP_API_URL + '/realstate/images/deleteAll/?property=' + instance.id, null)
            .then(response => {
                console.log("IMAGES DELETE SUCCESS", response);
                setNotify(response.imagesDeleted + " imagens eliminadas com sucesso");
                setFeed(oldf => oldf.filter(f => f.feed !== IMAGES)); // Delete all instance s from the feed 
                onUpdateLocal(); // Reload property because banner has changed
            }).catch(error => {
                handleRequestError(
                    error,
                    [],
                    "Error deleting images",
                ).then(e => {
                    setNotify("Ocorreu um erro, tente novamente", true);
                });
            }).finally(() => {
                setLoading(false);
            });
    }

    const navigate = useNavigate();
    const duplicateProperty = () => {

        setWindow(null);
        setLoading(true);

        requestWithBody("POST", process.env.REACT_APP_API_URL + '/realstate/properties/' + instance.id + '/duplicate/', null)
            .then(response => {
                console.log("PROPERTY DUPLICATE SUCCESS", response);

                // TODO! Redirect to new property with url param to notify creation
                navigate('/propriedades/' + response.created + '?duplicated=true');

            }).catch(error => {
                handleRequestError(
                    error,
                    [],
                    "Error duplicating property",
                ).then(e => {
                    setNotify("Ocorreu um erro, tente novamente", true);
                });
            }).finally(() => {
                setLoading(false);
            });

    }

    const ALERT_IMAGES_CONFIRM_DELETE_ALL = {
        title: 'Quer mesmo eliminar todas as imagens?',
        text: 'Esta operação é irreversível!',
        action: deleteAllImages
    };

    const ALERT_DUPLICATE_PROPERTY = {
        title: 'Quer mesmo duplicar esta propriedade?',
        text: 'Irá ser gerado um novo anúncio com uma nova referência. Esta operação é irreversível!',
        action: duplicateProperty
    };

    // On instance updated, reload some feed elements
    const onUpdateLocal = () => {
        // When property updated, reload activity log...
        getActivityLog();

        // Proceed to upper component
        onUpdate();
    }

    useEffect(getActivityLog, [instance]);


    return (
        <Grid
            container
            mt={5}
            className="instance-feed"
        >
            {
                loading &&
                <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
            <Grid xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="instance-feed-tabs">
                    <Tabs value={tab} onChange={(e, val) => setTab(val)} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                        {
                            // For properties all tabs are visible
                            Object.keys(TABS).map(t => <Tab label={t} value={t} />)
                        }
                    </Tabs>
                </Box>
                <Grid>
                    {
                        !loading &&
                        <>
                            <div className="instance-feed-operations">
                                {
                                    // OPERATIONS
                                }
                                {
                                    TABS[tab].some(v => v === DOCUMENTS) && instance.status !== PROPERTY_STATUS_ARCHIVED &&
                                    <Button
                                        sx={{ width: '100%', mt: 3 }}
                                        onClick={() => openDocumentFormWindow(null)}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        Carregar documentos
                                    </Button>
                                }
                                {
                                    TABS[tab].some(v => v === IMAGES) && instance.status !== PROPERTY_STATUS_ARCHIVED &&
                                    <>
                                        <Button
                                            sx={{ width: '100%', mt: 3 }}
                                            onClick={() => openImageFormWindow(false, false)}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Carregar imagens
                                        </Button>
                                        <Button
                                            sx={{ width: '100%', mt: 3 }}
                                            onClick={() => openImageFormWindow(true, false)}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Editar imagens
                                        </Button>
                                        <Button
                                            sx={{ width: '100%', mt: 3 }}
                                            onClick={() => openImageFormWindow(true, true)}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Ordenar imagens
                                        </Button>
                                        {
                                            (PROPERTY_STATUS_ALLOW_WRITE_ALWAYS.includes(instance.status) || canWriteSensitiveData) &&
                                            <Button
                                                sx={{ width: '100%', mt: 3 }}
                                                onClick={() => setAlert(ALERT_IMAGES_CONFIRM_DELETE_ALL)}
                                                color="warning"
                                                variant="outlined"
                                            >
                                                Eliminar todas as imagens
                                            </Button>
                                        }
                                    </>
                                }
                                {
                                    TABS[tab].some(v => v === PUBLIC) &&
                                    <>
                                        <Button
                                            sx={{ width: '100%', mt: 3 }}
                                            onClick={() => openPropertySheetFormWindow()}
                                            color="primary"
                                            variant="outlined"
                                        >
                                            Gerar folha de propriedade
                                        </Button>
                                        {
                                            instance.status === PROPERTY_STATUS_PUBLISHED &&
                                            <Button
                                                sx={{ width: '100%', mt: 3 }}
                                                color="primary"
                                                variant="outlined"
                                                target='_blank'
                                                href={process.env.REACT_APP_PUBLIC_URL + '/' + instance.id}
                                            >
                                                Página pública
                                            </Button>
                                        }
                                        {
                                            (PROPERTY_STATUS_ALLOW_WRITE_ALWAYS.includes(instance.status) || canWriteSensitiveData) &&
                                            <Button
                                                sx={{ width: '100%', mt: 3 }}
                                                onClick={() => setAlert(ALERT_DUPLICATE_PROPERTY)}
                                                color="secondary"
                                                variant="outlined"
                                            >
                                                Duplicar anúncio
                                            </Button>
                                        }
                                    </>
                                }
                            </div>
                            <div className="instance-feed-filters">
                                {
                                    // Only show filters when there are filters for tab and tab has any instance
                                    filters && tab in filters && TABS[tab].some(v => feed.some(f => f.feed === v)) &&
                                    <Grid
                                        container
                                        my={3}
                                        sx={{ alignItems: 'center' }}
                                    >
                                        <Typography mr={1}>Filtros:</Typography>
                                        {
                                            filters[tab].map(f =>
                                                <Chip
                                                    color="primary"
                                                    variant={filter && filter === f.value || (!filter && !f.value) ? 'contained' : 'outlined'}
                                                    onClick={() => setFilter(f.value)}
                                                    label={f.label}
                                                    sx={{
                                                        mr: 1,
                                                        p: 2
                                                    }}
                                                ></Chip>
                                            )
                                        }
                                    </Grid>
                                }
                            </div>
                            <div className="instance-feed-filters-time">
                                {
                                    // Only show filters when there are filters for tab and tab has any instance
                                    filtersTime && tab in filtersTime && TABS[tab].some(v => feed.some(f => f.feed === v)) &&
                                    <Grid
                                        container
                                        my={3}
                                        sx={{ alignItems: 'center' }}
                                        wrap='wrap'
                                    >
                                        <Grid>
                                            <FormGroup
                                                fields={[
                                                    buildFilterDateTime('Desde', 'from', true, false, false, false, new Date(filtersTime[tab].start), new Date(filtersTime[tab].end)),
                                                    buildFilterDateTime('Até', 'to', true, false, false, false, new Date(filtersTime[tab].start), new Date(filtersTime[tab].end))
                                                ]}
                                                initialVals={{
                                                    'from': filterTime && filterTime.from && new Date(filterTime.from),
                                                    'to': filterTime && filterTime.to && new Date(filterTime.to)
                                                }}
                                                updateParams={(to_clean, id, val) => {
                                                    if (!val) return;
                                                    var changes = { [id]: val };
                                                    // Validate changes to avoid from<to
                                                    if (id === 'from' && filterTime && filterTime.to && new Date(val) > new Date(filterTime.to))
                                                        changes['to'] = val;
                                                    else if (id === 'to' && filterTime && filterTime.from && new Date(val) < new Date(filterTime.from))
                                                        changes['from'] = val;
                                                    setFilterTime(oldF => { return { ...oldF, ...changes } });
                                                }}
                                            />
                                        </Grid>
                                        {
                                            filtersTimeSuggestion && filtersTimeSuggestion[tab] && filtersTimeSuggestion[tab].length > 0 &&
                                            <Grid sx={{ width: '100%' }} mt={2}>
                                                {
                                                    filtersTimeSuggestion[tab].map(f => {
                                                        var selected = filterTime && filterTime.to && filterTime.from && f.to && f.from && filterTime.from.split('T')[0] === f.from.split('T')[0] && filterTime.to.split('T')[0] === f.to.split('T')[0]
                                                        return <Chip
                                                            color="primary"
                                                            variant={selected ? 'contained' : 'outlined'}
                                                            label={f.label}
                                                            onClick={() => !selected && setFilterTime(f)}
                                                            sx={{
                                                                mr: 1,
                                                                p: 2
                                                            }}
                                                        ></Chip>
                                                    }
                                                    )
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </div>
                        </>
                    }
                    <Grid className="instance-feed-cards" container direction="row">
                        {
                            // FEED CARDS
                        }
                        {
                            // Sort by last update (first)
                            feed.sort(
                                (a, b) =>
                                    a.feed === IMAGES
                                        // Images order is by order attribute
                                        ? a.order > b.order
                                        // Clients have custom sort
                                        : a.feed === CLIENTS && b.feed === CLIENTS
                                            ? compareClientAgentForProperty(a, b)
                                            // Default order is last update and then creation (or epoch if none)
                                            : new Date(a.sent || a.last_changed || a.created || new Date(Date.UTC(96, 1, 2, 3, 4, 5))) < new Date(b.sent || b.last_changed || b.created || new Date(Date.UTC(96, 1, 2, 3, 4, 5)))
                            ).map(f => {
                                let counter = 0;
                                if (f.feed === APPS && TABS[tab].indexOf(APPS) >= 0) {
                                    counter++;
                                    return (
                                        <PropertyPublishedBanner
                                            app={f}
                                            property={instance}
                                            selected={windowId && windowId === (f.id + '/' + APPS)}
                                            setWindow={(w) => openWindow(w, w ? f.id + '/' + APPS : null, true)}
                                            updateElement={(instance, message) => {
                                                // Get app (instance is pp) and update it
                                                var app = feed.find(i => i.feed === APPS && i.id === instance.app.id);
                                                app.pp = instance;
                                                app.last_changed = instance.last_sync;
                                                // Propagate changes to feed
                                                updateFeed(app, APPS);
                                                setNotify(message ? message : "O anúncio foi atualizado com sucesso");
                                            }}
                                            setNotify={setNotify}
                                        />
                                    );
                                }
                                if (
                                    f.feed === DOCUMENTS && TABS[tab].indexOf(DOCUMENTS) >= 0 &&
                                    // Filtering
                                    (!filter || !filters || !tab in filters || !filters[tab] || f.category && f.category.id == filter)
                                ) {
                                    counter++;
                                    return (
                                        <PropertyDocumentBanner
                                            doc={f}
                                            property={instance}
                                            selected={windowId && windowId === (f.id + '/' + APPS)}
                                            setWindow={(w) => openWindow(w, w ? f.id + '/' + APPS : null, true)}
                                            updateElement={(instance, message, remove) => {
                                                updateFeed(instance, DOCUMENTS, true, remove);
                                                setNotify(message ? message : "O documento foi atualizado com sucesso");
                                            }}
                                            setNotify={setNotify}
                                        />
                                    )
                                }
                                if (
                                    f.feed === IMAGES && TABS[tab].indexOf(IMAGES) >= 0 &&
                                    // Filtering
                                    (!filter || !filters || !tab in filters || !filters[tab] || f.label && f.label.id == filter)
                                ) {
                                    counter++;
                                    return (
                                        <PropertyImageBanner
                                            instance={f}
                                            index={counter - 1}
                                            property={instance}
                                        />
                                    )
                                }
                                if (
                                    f.feed === STATS && TABS[tab].indexOf(STATS) >= 0
                                ) {
                                    // Stats must have filters
                                    if (!filtersTime || !tab in filtersTime || !filtersTime[tab]) {
                                        return (
                                            <Alert severity="error" sx={{ width: '100%' }}>
                                                Ocorreu um erro, por favor contacte o suporte técnico.
                                            </Alert>
                                        );
                                    } else if (!filterTime || !filterTime.from || !filterTime.to) {
                                        return (
                                            <Alert severity="info" sx={{ width: '100%' }}>
                                                Selecione um filtro temporal para ver as estatísticas.
                                            </Alert>
                                        );
                                    } else {
                                        return ['clients', 'shares'].map((s) => {
                                            return (
                                                <PropertyStatsBanner
                                                    start={filterTime && filterTime.from}
                                                    end={filterTime && filterTime.to}
                                                    subject={s}
                                                    property={instance}
                                                />
                                            )
                                        });
                                    }
                                }
                                if (
                                    f.feed === CLIENTS && TABS[tab].indexOf(CLIENTS) >= 0 &&
                                    // Filtering
                                    (
                                        // Filter not defined
                                        !filter || !filters || !tab in filters || !filters[tab]
                                        // If defined, must meet conditions
                                        || filter === 'forms' && f.forms > 0
                                        || filter === 'forms_pending' && f.forms_pending
                                        || filter === 'classifications' && f.classifications > 0
                                        || filter === 'visits' && f.visits > 0
                                        || filter === 'visits_next' && !!f.visits_next
                                    )
                                ) {
                                    return (
                                        <ClientAgentBanner
                                            clientAgent={f.clientAgent}
                                            extraTags={buildTagsForClientAgentForProperty(f)}
                                            nextVisit={f.visits_next}
                                        />
                                    )
                                }
                                if (
                                    f.feed === LOG && TABS[tab].indexOf(LOG) >= 0
                                ) {
                                    return (
                                        <ActivityLogTable
                                            log={f.log}
                                        />
                                    )
                                }
                            })
                        }
                        {
                            tab && TABS[tab] && feed.filter(f => TABS[tab].includes(f.feed)).length===0 &&
                            !TABS[tab].includes(PUBLIC) && !TABS[tab].includes(PRIVATE) && 
                            <Alert severity="info" sx={{ width: '100%', my: 3 }}>
                                Não foram encontrados elementos.
                            </Alert>
                        }
                        { /* STATIC FEED ELEMENTS */}
                        {
                            TABS[tab].indexOf(PUBLIC) >= 0 &&
                            <PropertyAdvert
                                property={instance}
                            />
                        }
                        {
                            TABS[tab].indexOf(PRIVATE) >= 0 &&
                            <PropertyPrivateData
                                property={instance}
                            />
                        }
                        {
                            !loading && feed.length == 0 &&
                            <Typography py={3}>
                                A propriedade não tem atividade registada.
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>

            { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default Feed;
